@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.ttf?rujz15') format('truetype'),
    url('fonts/icomoon.woff?rujz15') format('woff'),
    url('fonts/icomoon.svg?rujz15#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="intelli_"], [class*=" intelli_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.intelli_lunch:before {
  content: "\e900";
}
.intelli_access:before {
  content: "\e901";
}
.intelli_alarms:before {
  content: "\e902";
}
.intelli_cameras:before {
  content: "\e903";
}
.intelli_guest:before {
  content: "\e904";
}
.intelli_lights:before {
  content: "\e905";
}
.intelli_action_scheduled:before {
  content: "\e906";
}
.intelli_alerts:before {
  content: "\e907";
}
.intelli_analitycs:before {
  content: "\e908";
}
.intelli_calendar_lock:before {
  content: "\e909";
}
.intelli_change_status_door_manual:before {
  content: "\e90a";
}
.intelli_change_status_door_remote:before {
  content: "\e90b";
}
.intelli_close_door:before {
  content: "\e90c";
}
.intelli_closing_rule:before {
  content: "\e90d";
}
.intelli_connection_change:before {
  content: "\e90e";
}
.intelli_dashboard:before {
  content: "\e90f";
}
.intelli_device_group:before {
  content: "\e910";
}
.intelli_devices:before {
  content: "\e911";
}
.intelli_disabled_device:before {
  content: "\e912";
}
.intelli_door_close:before {
  content: "\e913";
}
.intelli_door_lock:before {
  content: "\e914";
}
.intelli_door_open:before {
  content: "\e915";
}
.intelli_dvr_play:before {
  content: "\e916";
}
.intelli_dvr-01:before {
  content: "\e917";
}
.intelli_dvr-02:before {
  content: "\e918";
}
.intelli_dvr-03:before {
  content: "\e919";
}
.intelli_dvr-04:before {
  content: "\e91a";
}
.intelli_dvr-05:before {
  content: "\e91b";
}
.intelli_dvr-06:before {
  content: "\e91c";
}
.intelli_dvr-07:before {
  content: "\e91d";
}
.intelli_emails:before {
  content: "\e91e";
}
.intelli_entity_type:before {
  content: "\e91f";
}
.intelli_events:before {
  content: "\e920";
}
.intelli_face:before {
  content: "\e921";
}
.intelli_fingerprint:before {
  content: "\e922";
}
.intelli_activar-estacion-de-trabajo:before {
  content: "\e923";
}
.intelli_activar-vehiculo:before {
  content: "\e924";
}
.intelli_cambio-de-perfil-a-vehiculo:before {
  content: "\e925";
}
.intelli_dispositivo-conectado:before {
  content: "\e926";
}
.intelli_inactivar-vehiculo:before {
  content: "\e927";
}
.intelli_marcaje-verificado:before {
  content: "\e928";
}
.intelli_firm:before {
  content: "\e929";
}
.intelli_iris:before {
  content: "\e92a";
}
.intelli_lock_door:before {
  content: "\e92b";
}
.intelli_messaging_text:before {
  content: "\e92c";
}
.intelli_open_door:before {
  content: "\e92d";
}
.intelli_open_exit_button:before {
  content: "\e92e";
}
.intelli_persons_vehicles_disabled:before {
  content: "\e92f";
}
.intelli_plan:before {
  content: "\e930";
}
.intelli_plate:before {
  content: "\e931";
}
.intelli_registration_station:before {
  content: "\e932";
}
.intelli_reproduction:before {
  content: "\e933";
}
.intelli_route:before {
  content: "\e934";
}
.intelli_timelog_accepted:before {
  content: "\e935";
}
.intelli_timelog_not_verified:before {
  content: "\e936";
}
.intelli_visit_change_status_scheduled:before {
  content: "\e937";
}
.intelli_visits_scheduled:before {
  content: "\e938";
}
.intelli_widgets:before {
  content: "\e939";
}
.intelli_work_stations_disabled:before {
  content: "\e93a";
}
.intelli_time:before {
  content: "\e93b";
}
.intelli_schedule:before {
  content: "\e93c";
}
.intelli_calendario:before {
  content: "\e93d";
}
.intelli_csv-file:before {
  content: "\e93e";
}
.intelli_download:before {
  content: "\e93f";
}
.intelli_editar:before {
  content: "\e940";
}
.intelli_xlsx-file:before {
  content: "\e941";
}
.intelli_lista:before {
  content: "\e942";
}
.intelli_lupa:before {
  content: "\e943";
}
.intelli_pdf-file:before {
  content: "\e944";
}
.intelli_txt-file:before {
  content: "\e945";
}
.intelli_novelties_permissions:before {
  content: "\e946";
}
.intelli_huella:before {
  content: "\e947";
}
